$('.video__slider').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 1000,
    adaptiveHeight: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: $(".slide__prev"),
    nextArrow: $(".slide__next"),
    responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      }
    },
   ]
});

$('.reviews__slider').slick({
    // autoplay: true,
    autoplaySpeed: 6000,
    speed: 1000,
    adaptiveHeight: true,
    prevArrow: $(".slide__prev_3"),
    nextArrow: $(".slide__next_3"),
});

$('.cases__slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    prevArrow: $(".slider__prev_2"),
    nextArrow: $(".slider__next_2"),
    responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      }
    },
   ]
});

$('[data-fancybox="gallery"]').fancybox({
    // Options will go here
});

function f_acc() {
    $("#accordeon .acc-body").not($(this).next()).slideUp(500).prev().removeClass("active"),
        $(this).next().slideToggle(500).prev().toggleClass("active");
}
$(document).ready(function() {
    $("#accordeon .acc-head").on("click", f_acc)
});

$('.slide__next--1').click(function() {
    $('.quiz__slide').hide('300');
    $('.quiz__slide--2').show('300');
});
$('.slide__next--2').click(function() {
    $('.quiz__slide').hide('300');
    $('.quiz__slide--3').show('300');
});
$('.slide__next--3').click(function() {
    $('.quiz__slide').hide('300');
    $('.quiz__slide--4').show('300');
});


$('.slide__next--5').click(function() {
    $('.quiz__slide').hide('300');
    $('.quiz__slide--1').show('300');
});

$('.slide__prev--1').click(function() {
    $('.quiz__slide').hide('300');
    $('.quiz__slide--1').show('300');
});

$('.slide__prev--2').click(function() {
    $('.quiz__slide').hide('300');
    $('.quiz__slide--2').show('300');
});

$('.slide__prev--3').click(function() {
    $('.quiz__slide').hide('300');
    $('.quiz__slide--3').show('300');
});
$('.slide__prev--4').click(function() {
    $('.quiz__slide').hide('300');
    $('.quiz__slide--4').show('300');
});


var $page = $('html, body');
$('a[href*="#"]').click(function() {
    $page.animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 1400);
    return false;
});

$('.open_modal').click(function() {
   $('#form_modal').arcticmodal();
});

$(function() {
    $('.quiz__form').submit(function(e) {
        var $form = $(this);
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize()
        }).done(function() {
            $("input, textarea").val("");
            $('.quiz__slide').hide('300');
            $('.quiz__slide--6').show('300');
        }).fail(function() {
            console.log('fail');
        });
        //отмена действия по умолчанию для кнопки submit
        e.preventDefault();
    });
});


$(function() {
    $('.order__form, .order__form--2').submit(function(e) {
        var $form = $(this);
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize()
        }).done(function() {
            $("input, textarea").val("");
            $('#thank_modal').arcticmodal();
            $('#form_modal').arcticmodal('close');
        }).fail(function() {
            console.log('fail');
        });
        //отмена действия по умолчанию для кнопки submit
        e.preventDefault();
    });
});

